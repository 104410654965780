import "../styles/pages/page-results.scss";
import React from "react";
import { Link } from "gatsby";
import moment from "moment";

import Layout from "components/Layout";
import Seo from "components/Seo";
import Breadcrumbs from "components/Breadcrumbs";
import SectionTitle from "components/SectionTitle";

const VotesResultsPage = ({ pageContext }) => {
   const seo = pageContext.seo;

   const votesAmount = pageContext.votesAmount.map((vote) => ({
      id: vote?.post,
      votes: Number(vote?.NumberOfVote),
   }));

   const formatted_votes = pageContext?.votes
      // ?.filter(
      //    (item) =>
      //       item?.singleVotePage?.endDate === "27.11.2022" ||
      //       item?.singleVotePage?.endDate === "24.11.2022"
      // )
      ?.filter(
         (item) =>
            item?.singleVotePage?.endDate?.split(".")?.[2] !== "2023" &&
            item?.singleVotePage?.endDate?.split(".")?.[2] !== "2022" &&
            item?.singleVotePage?.endDate?.split(".")?.[2] !== "2021"
      )
      ?.map((item) => ({
         url: item?.uri,
         title: item?.title,
         votes: votesAmount?.find((vote) => vote?.id === item?.id)?.votes || 0,
      }))
      .sort((a, b) => {
         return b.votes - a.votes;
      });

   const votesLabel = (num) => {
      if (num === 1) {
         return "głos";
      } else if (num % 10 === 2 || num % 10 === 3 || num % 10 === 4) {
         return "głosy";
      } else {
         return "głosów";
      }
   };

   const date_is_after = moment().isAfter(
      "2022-12-3 12:00",
      "year month day hour"
   );

   return (
      <Layout>
         <Seo
            title={seo.title ? seo.title : pageContext.title}
            description={seo.metaDesc}
         />
         <Breadcrumbs
            prevLocationLink="/glosowanie"
            prevLocationLabel="Głosowanie"
            currentLocationLabel={pageContext.title}
         />
         <section className="votes-results">
            <div className="container">
               <SectionTitle title={pageContext.title} />
               <div className="row justify-content-center">
                  <div
                     className="col-xl-10 votes-results__paragraph"
                     dangerouslySetInnerHTML={{
                        __html: pageContext.content,
                     }}
                  />
                  {!!date_is_after && (
                     <div className="col-xl-10">
                        <div className="votes-results__table">
                           {formatted_votes.map(
                              ({ url, title, votes }, index) => {
                                 return (
                                    <>
                                       <div
                                          className="votes-results__table-row"
                                          key={index}
                                       >
                                          <div
                                             className={
                                                index === 0
                                                   ? "votes-results__table-index votes-results__table-index--winner"
                                                   : "votes-results__table-index"
                                             }
                                          >
                                             {index + 1}
                                          </div>

                                          <div className="votes-results__table-title">
                                             <Link to={url}>{title}</Link>
                                          </div>
                                          <div
                                             className={
                                                index === 0
                                                   ? "votes-results__table-votes votes-results__table-votes--winner"
                                                   : "votes-results__table-votes"
                                             }
                                          >
                                             {votes}
                                             <span>{votesLabel(votes)}</span>
                                          </div>
                                          <div className="votes-results__table-spacer" />
                                          <Link
                                             className="button votes-results__table-button"
                                             to={url}
                                          >
                                             Więcej
                                          </Link>
                                       </div>
                                       {(index === 0 ||
                                          (index + 1) % 10 === 0) && (
                                          <div className="votes-results__row-spacer" />
                                       )}
                                    </>
                                 );
                              }
                           )}
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </section>
      </Layout>
   );
};

export default VotesResultsPage;
